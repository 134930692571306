import { Menu } from 'react-admin';
import Summaries from '@mui/icons-material/Summarize';

export const DinderMenu = () => (
    <Menu>
      <Menu.DashboardItem />
      <Menu.ResourceItem name="users"/>
      <Menu.ResourceItem name="paused"/>
      <Menu.ResourceItem name="deleted"/>
      <Menu.ResourceItem name="coordinators"/>
      <Menu.ResourceItem name="institutions"/>
      <Menu.ResourceItem name="plans"/>
      <Menu.ResourceItem name="meetings"/>
      <Menu.ResourceItem name="reports"/>
      <Menu.ResourceItem name="questions"/>
      <Menu.ResourceItem name="interests"/>
      <Menu.ResourceItem name="notifications"/>
      <Menu.ResourceItem name="meeting-places"/>
      <Menu.ResourceItem name="resources"/>
      <Menu.ResourceItem name="blocks"/>
      <Menu.ResourceItem name="summaries"/>
      <Menu.Item to="/summaries" primaryText="Summaries" leftIcon={<Summaries />} />
      <Menu.ResourceItem name="comparison"/>
      <Menu.Item to="/summaries/comparison" primaryText="Comparison" leftIcon={<Summaries />} />
  </Menu>
);